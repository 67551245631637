import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import CRUDPage from './CRUDPage';
import submenuLegos from './menus/submenu-legos';
import { Icon } from '../components/common/Icon';
import { DataIssuesFiltersBar } from '../components/data-issues/DataIssuesFiltersBar';
import DataIssueEditor from '../components/data-issues/DataIssueEditor';
import { OpenClosedIcon } from '../components/data-issues/OpenClosedIcon';
import { StateBadge } from '../components/data-issues/StateBadge';
import NiceDate from '../components/common/NiceDate';
import LegoContextSummary from '../components/lego/LegoContextSummary';
import _ from 'lodash';
import LegoLabel from '../components/lego/LegoLabel';
import ObjectSearchHandler from '../components/common/ObjectSearchHandler';
import ImageClusterPreview from '../components/images/ImageClusterPreview';
import { IconButton } from '../components/common/IconButton';
import ModalBatchEditLabels from '../components/lego/ModalBatchEditLabels';
import downloadFile from '../components/downloadFile';


dayjs.extend(relativeTime);


export default class DataIssuesManager extends CRUDPage {
  constructor(props) {
    super(props, 'services/data/fusebox-data-issues');
    this.submenu = submenuLegos;

    this.DataIssueAggregationService = this.service('services/data/issues/aggregation')

    this.rankService = this.service('/services/legos/rank');

    this.canDelete = true;
    this.canCreate = false;
  }

  getRankQuery() {
    // Make an object shaped like a lego to reuse lego rank service by context
    const legoData = this.state.objects.map(({_id, dataSelector}) => ({_id, context: [dataSelector.vehicle]}));
    return {query: {legos: legoData, rankCriteria: this.state.rankCriteria}};
  }

  getFacetFiltersComponent() {
    return <DataIssuesFiltersBar onChange={this.onFiltersChange.bind(this)} filter={this.state.facetFilters}/>
  }

  getSearchHandler() {
    const dataIssueSearchFilters = {
      label: {
        filter: labelFilter => (l => (l.labels?.length && (!labelFilter || _.some(l.labels, x => x.includes(labelFilter.toLowerCase()))))),
        description: 'With labels [=<prefix>...]'
      },
      s: {
        filter: txt => (l => l.state && (!txt || l.state.startsWith(txt))),
        description: 'With state [=<prefix>...]'
      },
      involved: {
        filter: txt => (l => l.involvedDataFeedback && l.involvedDataFeedback.length >= parseInt(txt)),
        description: 'With involved data feedbacks >=<n>...]'
      },
    };

    const nodeTextSearchCustomizer = text => text.replace(/\s+/g, '[\\w_\\s]*');

    return new ObjectSearchHandler(dataIssueSearchFilters, nodeTextSearchCustomizer);
  }

  buildObjectQuery() {
    let query = super.buildObjectQuery();

    let make = query['dataSelector.vehicle.make'];
    if(make) {
      query['dataSelector.vehicle.modelId'] = {$regex: `^${make}-`};
      delete query['dataSelector.vehicle.make'];
      query.$limit = 5000;
    }
    if(query['dataSelector.vehicle.modelId']) {
      query.$limit = 5000;
    }
    return query;
  }

  async openBatchEditLabels(issues) {
    const svc = this.service('/services/data/issues')

    this.openModal(<ModalBatchEditLabels docs={issues} service={svc}
                                         onSave={() => this.fetchObjects()}
                                         onCancel={() => this.closeModal()}/>, {title: 'Edit labels in batch'});
  }

  exportAsCsv(issues) {
    const rows = issues.map(({dataSelector, _id, involvedDataFeedback, data, labels}) => {
      return [dataSelector.vehicle.modelId, dataSelector.vehicle.year, _id.toString(), involvedDataFeedback?.length, data.clusterId, _.uniq(labels || []).join(' ')]
    })

    const text = rows.join('\n');

    downloadFile(text, `data_issues_${issues.length}.csv`, 'text/csv');
  }

  getSelectionButtons(selection) {
    if(selection?.length) {
      return <>
        <IconButton icon={'app_registration'} title={'Edit issues in batch'} onClick={() => this.openBatchEditLabels(selection)}></IconButton>
        <IconButton icon={'view_list'} title={'Export as csv'} onClick={() => this.exportAsCsv(selection)}></IconButton>
      </>
    }
  }

  async fetchObjects() {
    // Change $limit so that UI knows we are showing all the objects (not the first 20)
    if (this.state.facetFilters.top100 || this.state.facetFilters.groupByCluster) {
      await this.fetchGroupedByClusterId();
    } else {
      await super.fetchObjects({ $sort: { updatedAt: -1 }, $limit: 100 });
    }
  }

  orderRows() {
    if (this.state.facetFilters.groupByCluster) {
      if(!this.state.objects?.[0]?.isGroupedByCluster) {
        let sortedRows = _.orderBy(this.state.objects, o => o.involvedDataFeedback.length, ['desc']);
        let groupedRows = _.groupBy(sortedRows, r => r?.data?.clusterId || r._id.toString());

        this.canEdit = false;
        this.canDelete = false;

        let objects = _.values(groupedRows).map((issues) => {
          let first = issues[0];

          return {
            isOpen: _.uniq(issues.map(i => i.isOpen)).length === 1 ? first.isOpen : null,
            labels: _.union(..._.compact(issues.map(i => i.labels))),
            state: _.uniq(issues.map(i => i.state)).length === 1 ? first.state : 'mixed...',
            dataSelector: { vehicle: { modelId: first.dataSelector?.vehicle?.modelId } },
            involvedDataFeedback: _.flatten(issues.map(di => di.involvedDataFeedback)),
            dataIssuesCount: (issues.length),
            dataIssuesIds: _.map(issues, '_id'),
            data: { clusterId: first.data?.clusterId },
            _id: '...' + (first.data.clusterId || '').slice(-8),
            updatedAt: _.max(issues.map(i => i.updatedAt)),
            isGroupedByCluster: true
          };
        });

        // Sort again by group feedbacks count
        objects = _.orderBy(objects, o => o.involvedDataFeedback.length, ['desc'])
        this.setState({ objects }, () => super.orderRows());
      } else {
        super.orderRows()
      }
    } else {
      this.canEdit = true;
      this.canDelete = true;
      super.orderRows();
    }
  }

  async fetchGroupedByClusterId() {
    this.setState({ loading: true });
    let query = this.buildObjectQuery();

    if(this.state.allResults) {
      query.$limit = 10000;
    }

    let data = await this.DataIssueAggregationService.find({ query});

    this.setState({
      objects: data,
      loading: false,
      isPartialResult: query.$limit !== 10000 && data.length >= 100,
      columnSort: {index: 2, asc: false, sorter: o => o.involvedDataFeedback.length}
    }, () => this.orderRows());

  }

  getColumnsDefinition(objects) {
    return [
      { content: 'State', className: 'text-center', sorter: 'state' },
      { content: 'Last update', className: 'text-center', sorter: 'updatedAt' },
       ...this.getRankColumnHeaders(),
      { content: 'Involved', className: 'text-center', sorter: o => o.involvedDataFeedback.length },
      { content: 'Category', className: 'text-center', sorter: 'issueCategory' },
      { content: 'Context', className: 'text-center', sorter: ['dataSelector.vehicle.modelId', 'dataSelector.vehicle.year'] },
      { content: 'Blocked', className: 'text-center', sorter: 'blockedBy'},
      { content: 'Data', className: 'text-center', sorter: o => JSON.stringify(o.data) }
    ];
  }

  getObjectColumns(dataIssue) {
    let {
      _id, updatedAt, dataIssuesCount, dataSelector, state, data, blockedBy, dataType, involvedDataFeedback, issueCategory, notes, labels, source, isOpen
    } = dataIssue;


    let {groupByCluster, top100, ... restOfFilter} = this.state.facetFilters || {};
    const onFilterCluster = () => {
      // TODO: The most ugly hack ever to simulate input in a controlled input. Find a cleaner way
      let textarea = this.searchInputRef.current;
      let lastValue = textarea.value;
      textarea.value = data.clusterId;
      textarea._valueTracker?.setValue(lastValue)
      const event = new Event('input', { bubbles: true});
      textarea.dispatchEvent(event);

      const model = {modelId: dataSelector.vehicle.modelId};
      this.onFiltersChange({'dataSelector.vehicle': model, ... restOfFilter});
    };

    return [
      <td key={'feedbackType'} className={'pl-3 text-center'}>
        <OpenClosedIcon isOpen={isOpen}/>
        <div className={'mt-1'}><StateBadge state={state}/></div>
        { labels?.length ? _.map(labels, l => <LegoLabel label={l} key={l} className={'ml-1'}/>) : null }
      </td>,

      <td key={'id'}>
        <NiceDate t={updatedAt}/>
        <div className={'text-secondary small'}>{_id}</div>
      </td>,

      ... this.getRankColumns(dataIssue),

      <td  key={'count'} className={'text-center no-wrap'} title={`${involvedDataFeedback?.length || 0} involved data feedbacks`}>
        {involvedDataFeedback?.length ? <span>
        <Icon icon={'person'} level={'secondary'} className={'h3 m-0'}/>
        <strong className={'align-middle'}>{involvedDataFeedback.length}</strong>
      </span> : null }

        {dataIssuesCount ? <div className={'small text-info'}>{dataIssuesCount} data issues</div> : null}
      </td>,

      <td  key={'type'}>
        <span className={'badge badge-secondary'}>{issueCategory}</span><br/>
        <span className={'badge badge-light'}>{dataType}</span>
      </td>,

      <td  key={'context'}>
        <LegoContextSummary context={dataSelector.vehicle || {}}/>
      </td>,


      <td  key={'blocked'}>
        {blockedBy?.length ? <span className={'badge badge-dark'}>BLOCKED</span> : null}
      </td>,

      <td  key={'dada'}>
        { data?.clusterId ?  <ImageClusterPreview filterByYear={dataSelector?.vehicle?.year} clusterId={data.clusterId} maxImages={1}/>: null}

        <span className={'inline-block ml-1 align-middle'}>
        {
          _.map(data, (val,key) => {
              return <div key={key} className={'small'}>
                <span className={'text-secondary'}>{key}:</span>
                <span className={'ml-1 text-info monospace break-word-all'}>{JSON.stringify(val)}</span>
                {groupByCluster && val && key === 'clusterId' ? <IconButton icon={'filter_alt'} onClick={onFilterCluster}/> : null}
              </div>;
          })
        }
        </span>
      </td>
    ];
  }

  getObjectEditorComponent(obj, defaultOnSaveCbk, defaultOnCancelCbk) {
    return <DataIssueEditor obj={obj} onSave={defaultOnSaveCbk} onCancel={defaultOnCancelCbk}/>
  }
}
